import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { observes } from '@ember-decorators/object';
import OrderService from '../../services/order';
import { colors } from './colors';
import { Order } from '../../services/api-client';

export default class AppCabincolors extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service() amplify: any;
  @service('order') orderService!: OrderService;

  itemHeight: number = 100;
  columns: [number] = [213];
  height: number = 0;
  esd: boolean = false;
  defaultFilter: boolean = false;

  edelstahl: boolean = false;
  stahlblech: boolean = true;
  finish1: boolean = false;
  finish2: boolean = true;

  activeItem = undefined;
  model!: Order;

  filter: string = '';

  didInsertElement() {
    const h = this.$().find('.card.card-chart').height();
    if (h == undefined) {
      console.error('card-chart not found');
      return;
    }
    this.set('height', h - 20);
    console.log(this.get('model.color'));

    this.set('activeItem', this.get('model.color'));
  }

  @computed('model', 'model.status')
  get isOrderReadonly() {
    return (
      (this.model.status == 'ORDERED' && this.model.ordernumber != null) ||
      (this.model.status === 'NEU' && this.model.ordernumber !== null)
    );
  }

  @computed('model', 'model.status', 'model.ordernumber', 'model.material', 'model.color')
  get isStructureSmoothShinyDisabled() {
    return (
      (this.model.status == 'ORDERED' && this.model.ordernumber != null) ||
      (this.model.status === 'NEU' && this.model.ordernumber !== null) ||
      this.model.material == 'Edelstahl' ||
      ((this.model.color as any).esd != undefined &&
        (this.model.color as any).esd &&
        !(this.model.color as any).esd_structures.shiny_smooth)
    );
  }

  @computed('model', 'model.status', 'model.ordernumber', 'model.material', 'model.color')
  get isStructureRoughShinyDisabled() {
    return (
      (this.model.status == 'ORDERED' && this.model.ordernumber != null) ||
      (this.model.status === 'NEU' && this.model.ordernumber !== null) ||
      this.model.material == 'Edelstahl' ||
      ((this.model.color as any).esd != undefined &&
        (this.model.color as any).esd &&
        !(this.model.color as any).esd_structures.shiny_rough)
    );
  }

  @observes('model.color')
  observeModelColor() {
    this.set('activeItem', this.get('model.color'));
    console.log('OBSERVE', this.activeItem, this.get('model.color'));
  }

  @computed('filter', 'colors', 'esd', 'defaultFilter')
  get filteredColors() {
    let colors = this.colors;
    if (this.esd) {
      colors = colors.filter((item) => item.esd);
    }
    if (this.defaultFilter) {
      colors = colors.filter((item) => item.standard == true);
    }

    if (isEmpty(this.get('filter'))) {
      return colors;
    } else {
      return this.get('colors').filter((color: any) => {
        return color.code.includes(this.filter) || color.name.includes(this.filter);
      });
    }
  }

  @computed()
  get colors() {
    return colors;
  }
  didReceiveAttrs() {
    if (this.get('model.material') == 'Edelstahl') {
      this.set('edelstahl', true);
      this.set('stahlblech', false);
    } else {
      this.set('edelstahl', false);
      this.set('stahlblech', true);
    }
    if (this.get('model.finish') == 'Glatt glänzend') {
      this.set('finish1', true);
      this.set('finish2', false);
    } else {
      this.set('finish1', false);
      this.set('finish2', true);
    }
  }
  @action
  switchMaterial() {
    this.toggleProperty('edelstahl');
    this.toggleProperty('stahlblech');
    if (this.edelstahl) {
      this.set('model.material', 'Edelstahl');
      this.set('model.color', {
        code: 'keine',
        name: 'Light grey',
        rgb_approx: '215-215-215',
        rgb_hex: '#D7D7D7'
      });
      this.set('finish1', false);
      this.set('finish2', false);
      this.set('model.finish', '');
      this.removeColor();
    }
    if (this.stahlblech) {
      this.set('model.material', 'Stahlblech');
    }
    this.orderService.saveOrder(this.get('model'));
  }
  @action
  switchFinish(finish: string) {
    if (this.get('model.finish') === finish) {
      this.set('model.finish', '');
      this.set('model.color', {
        code: 'keine',
        name: 'Light grey',
        rgb_approx: '215-215-215',
        rgb_hex: '#D7D7D7'
      });
    } else {
      this.set('model.finish', finish);
      if (this.get('model.color.code') == 'keine') {
        this.set('model.color', {
          code: '9022',
          name: 'Light grey',
          rgb_approx: '215-215-215',
          rgb_hex: '#D7D7D7'
        });
      }
    }
    this.orderService.saveOrder(this.get('model'));
  }

  @action
  toggleEsd() {
    this.set('esd', !this.esd);
  }

  @action
  toggleDefaultFilter() {
    this.set('defaultFilter', !this.defaultFilter);
  }

  @action
  searchRal(color: string) {
    console.log(color);
  }

  @action
  onColorChange(item: any) {
    this.set('activeItem', item);
  }

  @action
  removeColor() {
    this.amplify.trigger('3DView:removeColor');
    // $("#RALWahlText").html("Keine");
    // $("#RALWahl").css({ "background-image" : "url('/images/icons/ral_keine.jpg')" , "background-color" : "transparent" });
    this.set('activeItem', undefined);
  }

  @action
  handlePageBottom() {}
}
